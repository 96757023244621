.light-theme-vars {
    --surface-stronger: 220deg 42.8571428571% 98.6274509804%;
    --surface-strong: 200deg 33.3333333333% 98.2352941176%;
    --surface-main: 210deg 40% 98.0392156863%;
    --surface-weak: 200deg 12% 95.0980392157%;
    --surface-weaker: 200deg 6.6666666667% 91.1764705882%;
    --surface-contrast-weaker: 0deg 0% 0%;
    --surface-contrast-weak: 0deg 0% 0%;
    --surface-contrast-main: 0deg 0% 0%;
    --surface-contrast-strong: 0deg 0% 14.9019607843%;
    --surface-contrast-stronger: 0deg 0% 25.0980392157%;
    --primary-weaker: 313.9726027397deg 37.8238341969% 62.1568627451%;
    --primary-weak: 314.4578313253deg 37.899543379% 57.0588235294%;
    --primary-main: 314.0816326531deg 38.8888888889% 49.4117647059%;
    --primary-strong: 314.5263157895deg 38.7755102041% 48.0392156863%;
    --primary-stronger: 314.5054945055deg 38.7234042553% 46.0784313725%;
    --primary-contrast-stronger: 0deg 0% 100%;
    --primary-contrast-strong: 0deg 0% 100%;
    --primary-contrast-main: 0deg 0% 100%;
    --primary-contrast-weak: 314.5263157895deg 38.7755102041% 48.0392156863%;
    --primary-contrast-weaker: 314.5054945055deg 38.7234042553% 46.0784313725%;
    --secondary-weaker: 258.8571428571deg 15.2838427948% 55.0980392157%;
    --secondary-weak: 259.5deg 16% 49.0196078431%;
    --secondary-main: 259.5652173913deg 22.5490196078% 40%;
    --secondary-strong: 259.0909090909deg 22.2222222222% 38.8235294118%;
    --secondary-stronger: 259.5348837209deg 22.7513227513% 37.0588235294%;
    --secondary-contrast-stronger: 0deg 0% 100%;
    --secondary-contrast-strong: 0deg 0% 100%;
    --secondary-contrast-main: 0deg 0% 100%;
    --secondary-contrast-weak: 259.0909090909deg 22.2222222222% 38.8235294118%;
    --secondary-contrast-weaker: 259.5348837209deg 22.7513227513% 37.0588235294%;
    --info-weaker: 208.2352941176deg 34.3434343434% 61.1764705882%;
    --info-weak: 208.0519480519deg 34.2222222222% 55.8823529412%;
    --info-main: 207.6923076923deg 37.1428571429% 48.0392156863%;
    --info-strong: 207.9545454545deg 36.974789916% 46.6666666667%;
    --info-stronger: 207.8571428571deg 36.8421052632% 44.7058823529%;
    --info-contrast-stronger: 0deg 0% 100%;
    --info-contrast-strong: 0deg 0% 100%;
    --info-contrast-main: 0deg 0% 100%;
    --info-contrast-weak: 207.9545454545deg 36.974789916% 46.6666666667%;
    --info-contrast-weaker: 207.8571428571deg 36.8421052632% 44.7058823529%;
    --success-weaker: 140.1680672269deg 55.3488372093% 57.8431372549%;
    --success-weak: 140.2941176471deg 55.737704918% 52.1568627451%;
    --success-main: 140deg 71.3004484305% 43.7254901961%;
    --success-strong: 139.8701298701deg 71.2962962963% 42.3529411765%;
    --success-stronger: 139.8648648649deg 71.1538461538% 40.7843137255%;
    --success-contrast-stronger: 0deg 0% 100%;
    --success-contrast-strong: 0deg 0% 100%;
    --success-contrast-main: 0deg 0% 100%;
    --success-contrast-weak: 139.8701298701deg 71.2962962963% 42.3529411765%;
    --success-contrast-weaker: 139.8648648649deg 71.1538461538% 40.7843137255%;
    --warning-weaker: 22.6666666667deg 83.850931677% 68.431372549%;
    --warning-weak: 22.5deg 83.5164835165% 64.3137254902%;
    --warning-main: 22.4581005587deg 83.2558139535% 57.8431372549%;
    --warning-strong: 22.4137931034deg 77.6785714286% 56.0784313725%;
    --warning-stronger: 22.4096385542deg 70.3389830508% 53.7254901961%;
    --warning-contrast-stronger: 0deg 0% 100%;
    --warning-contrast-strong: 0deg 0% 100%;
    --warning-contrast-main: 0deg 0% 100%;
    --warning-contrast-weak: 22.4137931034deg 77.6785714286% 56.0784313725%;
    --warning-contrast-weaker: 22.4096385542deg 70.3389830508% 53.7254901961%;
    --error-weaker: 10.0699300699deg 67.1361502347% 58.2352941176%;
    --error-weak: 10deg 66.9421487603% 52.5490196078%;
    --error-main: 10.0523560209deg 84.8888888889% 44.1176470588%;
    --error-strong: 10.3225806452deg 85.3211009174% 42.7450980392%;
    --error-stronger: 10.1694915254deg 84.6889952153% 40.9803921569%;
    --error-contrast-stronger: 0deg 0% 100%;
    --error-contrast-strong: 0deg 0% 100%;
    --error-contrast-main: 0deg 0% 100%;
    --error-contrast-weak: 10.3225806452deg 85.3211009174% 42.7450980392%;
    --error-contrast-weaker: 10.1694915254deg 84.6889952153% 40.9803921569%;
}
