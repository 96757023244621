.dark-theme-vars {
    --surface-stronger: 220deg 3.3707865169% 34.9019607843%;
    --surface-strong: 230deg 4.4776119403% 26.2745098039%;
    --surface-main: 223deg 11% 13%;
    --surface-weak: 222.8571428571deg 10.7692307692% 12.7450980392%;
    --surface-weaker: 220deg 9.6774193548% 12.1568627451%;
    --surface-contrast-weaker: 0deg 0% 92.9411764706%;
    --surface-contrast-weak: 0deg 0% 96.862745098%;
    --surface-contrast-main: 0deg 0% 100%;
    --surface-contrast-strong: 0deg 0% 100%;
    --surface-contrast-stronger: 0deg 0% 100%;
    --primary-stronger: 337.5deg 42.1052631579% 85.0980392157%;
    --primary-strong: 338.3333333333deg 41.8604651163% 83.137254902%;
    --primary-main: 337.1428571429deg 41.1764705882% 80%;
    --primary-weak: 337.5deg 35.0877192982% 77.6470588235%;
    --primary-weaker: 336.9230769231deg 29.7709923664% 74.3137254902%;
    --primary-contrast-weaker: 0deg 0% 0%;
    --primary-contrast-weak: 0deg 0% 0%;
    --primary-contrast-main: 0deg 0% 0%;
    --primary-contrast-strong: 0deg 0% 14.9019607843%;
    --primary-contrast-stronger: 0deg 0% 25.0980392157%;
    --secondary-stronger: 260deg 23.6842105263% 85.0980392157%;
    --secondary-strong: 260deg 24.1379310345% 82.9411764706%;
    --secondary-main: 260deg 23.5294117647% 80%;
    --secondary-weak: 260deg 21.0526315789% 77.6470588235%;
    --secondary-weaker: 259.0909090909deg 16.9230769231% 74.5098039216%;
    --secondary-contrast-weaker: 0deg 0% 0%;
    --secondary-contrast-weak: 0deg 0% 0%;
    --secondary-contrast-main: 0deg 0% 0%;
    --secondary-contrast-strong: 0deg 0% 14.9019607843%;
    --secondary-contrast-stronger: 0deg 0% 25.0980392157%;
    --info-stronger: 209.3617021277deg 38.2113821138% 75.8823529412%;
    --info-strong: 209.4339622642deg 38.1294964029% 72.7450980392%;
    --info-main: 209.0322580645deg 37.8048780488% 67.8431372549%;
    --info-weak: 209deg 34.4827586207% 65.8823529412%;
    --info-weaker: 208.9655172414deg 30.8510638298% 63.137254902%;
    --info-contrast-weaker: 0deg 0% 0%;
    --info-contrast-weak: 0deg 0% 0%;
    --info-contrast-main: 0deg 0% 0%;
    --info-contrast-strong: 0deg 0% 14.9019607843%;
    --info-contrast-stronger: 0deg 0% 25.0980392157%;
    --success-stronger: 140deg 71.0526315789% 85.0980392157%;
    --success-strong: 139.6721311475deg 70.1149425287% 82.9411764706%;
    --success-main: 140deg 70.5882352941% 80%;
    --success-weak: 139.7142857143deg 61.4035087719% 77.6470588235%;
    --success-weaker: 140.5970149254deg 51.1450381679% 74.3137254902%;
    --success-contrast-weaker: 0deg 0% 0%;
    --success-contrast-weak: 0deg 0% 0%;
    --success-contrast-main: 0deg 0% 0%;
    --success-contrast-strong: 0deg 0% 14.9019607843%;
    --success-contrast-stronger: 0deg 0% 25.0980392157%;
    --warning-stronger: 48.4615384615deg 63.9344262295% 76.0784313725%;
    --warning-strong: 48.5393258427deg 64.0287769784% 72.7450980392%;
    --warning-main: 48.5714285714deg 64.4171779141% 68.0392156863%;
    --warning-weak: 48.8235294118deg 58.6206896552% 65.8823529412%;
    --warning-weaker: 48.8659793814deg 51.871657754% 63.3333333333%;
    --warning-contrast-weaker: 0deg 0% 0%;
    --warning-contrast-weak: 0deg 0% 0%;
    --warning-contrast-main: 0deg 0% 0%;
    --warning-contrast-strong: 0deg 0% 14.9019607843%;
    --warning-contrast-stronger: 0deg 0% 25.0980392157%;
    --error-stronger: 9.8901098901deg 85.046728972% 79.0196078431%;
    --error-strong: 9.9029126214deg 85.1239669421% 76.2745098039%;
    --error-main: 9.9173553719deg 84.6153846154% 71.9607843137%;
    --error-weak: 10.1694915254deg 76.6233766234% 69.8039215686%;
    --error-weaker: 10.0884955752deg 66.8639053254% 66.862745098%;
    --error-contrast-weaker: 0deg 0% 0%;
    --error-contrast-weak: 0deg 0% 0%;
    --error-contrast-main: 0deg 0% 0%;
    --error-contrast-strong: 0deg 0% 14.9019607843%;
    --error-contrast-stronger: 0deg 0% 25.0980392157%;
}
